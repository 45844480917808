
import { defineComponent, ref } from 'vue';
import { timeZone, setting, community } from '@/data';
import { TimeFormData } from '@/views/pm/setting/all.type';
import HttpRequest from '@/util/axios.config';

export default defineComponent({
    components: {},
    setup() {
        const formRef = ref<any>();
        const formData = ref<TimeFormData>({
            TimeZone: '',
            CustomizeForm: '2'
        });

        const timeFormat = ref<1 | 2>();
        const dateFormat = ref<1 | 3 | 5>();
        const getTimeFormat = (type: string) => {
            switch (type) {
                case '2':
                    timeFormat.value = 1;
                    dateFormat.value = 1;
                    break;
                case '3':
                    timeFormat.value = 2;
                    dateFormat.value = 1;
                    break;
                case '4':
                    timeFormat.value = 1;
                    dateFormat.value = 3;
                    break;
                case '5':
                    timeFormat.value = 2;
                    dateFormat.value = 3;
                    break;
                case '6':
                    timeFormat.value = 1;
                    dateFormat.value = 5;
                    break;
                case '7':
                    timeFormat.value = 2;
                    dateFormat.value = 5;
                    break;
                default:
                    timeFormat.value = 1;
                    dateFormat.value = 1;
                    break;
            }
        };

        setting.getPMCommunityDetail((res: TimeFormData) => {
            Object.keys(formData.value).forEach((item) => {
                const key = item as keyof TimeFormData;
                if (key === 'CustomizeForm') {
                    getTimeFormat(res[key]);
                    formData.value[key] = res[key];
                } else if (res[key] !== null) {
                    formData.value[key] = res[key];
                }
            });
        });

        const submit = () => {
            formData.value.CustomizeForm = ((timeFormat.value as number) + (dateFormat.value as number)).toString();
            HttpRequest.post(`v3/web/${community.ProjectType.value}/${community.ProjectType.value}Data/setTime`, formData.value, () => {
                // 新PM要更新data里面的时间格式
                community.customizeForm.value = formData.value.CustomizeForm;
                localStorage.setItem('CommunityCustomizeForm', formData.value.CustomizeForm);
            });
        };

        return {
            formRef,
            formData,
            submit,
            timeZone,
            timeFormat,
            dateFormat
        };
    }
});
