
import {
    computed,
    defineComponent, PropType,
    ref, watch, Ref
} from 'vue';
import { propertyBaseRouter, propertySubRouter } from '@/router/data';
import router from '@/router/index';
import secondaryNav from '@/components/common/secondary-nav/index.vue';
import propertyBreadCrumb from '@/components/view/pm/bread-crumb';
import {
    basicSetting, timeSetting,
    motionSetting, visitorSetting,
    communalFeeSetting
} from '@/views/pm/setting/settings-module';
import { community } from '@/data';
import {
    getMenus
} from './util';
import { SettingType } from './all.type';

const breadCrumb = [{ label: WordList.ProperAllTextSetting }];
export default defineComponent({
    components: {
        secondaryNav,
        propertyBreadCrumb,
        basicSetting,
        timeSetting,
        motionSetting,
        visitorSetting,
        communalFeeSetting
    },
    props: {
        type: {
            type: String as PropType<SettingType>,
            default: 'BasicSetting'
        }
    },
    setup(props) {
        // 办公隐藏visitor setting
        const secondMenus: Ref< { label: string; key: string; path: string }[] > = ref([]);
        watch([community.ProjectType, community.isNew], () => {
            secondMenus.value = getMenus(propertyBaseRouter, community.ProjectType.value, community.isNew.value);
        }, {
            immediate: true
        });
        console.log(1);

        const activeNavItem = computed(() => props.type);
        const changePath = (key: string, path: string) => {
            router.push(path);
        };

        return {
            changePath,
            secondMenus,
            breadCrumb,
            router,
            propertySubRouter,
            propertyBaseRouter,
            activeNavItem
        };
    }
});
